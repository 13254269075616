<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Equipment
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <!-- <button
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Equipment</span>
      </button> -->
    </div>

    <div class="flex flex-wrap">
      <vue-good-table
        v-if="equipment.length > 0"
        class="w-full"
        styleClass="vgt-table condensed"
        mode="remote"
        :rows="equipment"
        :columns="columns"
        :totalRows="totalEquipmentItems"
        @on-row-click="openEquipmentDetails"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :pagination-options="{
          enabled: true,
          perPage: 50,
          perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
          dropdownAllowAll: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_loan_unit'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700':
                  props.row.is_loan_unit == false,
                'bg-green-200 text-green-700': props.row.is_loan_unit == true,
              }"
              >{{ props.row.is_loan_unit ? "Yes" : "No" }}</span
            >
          </span>
          <span v-else-if="props.column.field == 'is_operational'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700':
                  props.row.is_operational == false,
                'bg-green-200 text-green-700': props.row.is_operational == true,
              }"
              >{{ props.row.is_operational ? "Yes" : "No" }}</span
            >
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p
        class="mx-auto my-16"
        v-if="viewState == 'Idle' && (!equipment || equipment.length == 0)"
      >
        There is no Equipment on this Contact.
      </p>
      <div
        v-if="viewState == 'Loading'"
        class="flex w-full justify-center py-20"
      >
        <Spinner :color="'text-black'" :size="10" />
      </div>
    </div>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const Spinner = () => import("@/components/Spinner.vue");
const AddEditContact = () =>
  import("@/components/SalesProspects/Edit/AddEditContact.vue");

export default {
  name: "Equipment",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    Spinner,
    AddEditContact,
  },
  props: {
    contactId: String,
  },
  data() {
    return {
      equipment: [],
      viewState: "Idle",
      totalEquipmentItems: 0,
      pagingParams: {
        page: 1,
        limit: 50,
      },
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "Product Code",
          field: "product_code",
        },
        {
          label: "Make",
          field: "make",
        },
        {
          label: "Model",
          field: "model_name",
        },
        {
          label: "Serial No.",
          field: "serial_number",
        },
        {
          label: "Location",
          field: "location",
        },
        {
          label: "Loan Unit",
          field: "is_loan_unit",
        },
        {
          label: "Operational",
          field: "is_operational",
        },
      ],
    };
  },
  computed: {},
  async mounted() {
    this.viewState = "Loading";
    await this.loadEquipment();
    this.viewState = "Idle";
    /* try {
      let result = await this.ContactService.getEquipment(this.contactId);
      this.equipment = result.data;
      this.totalEquipmentItems = result.count;
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
    } */
  },
  methods: {
    addEquipment: function() {
      this.editingContact = {};
      this.addEditContactPanelOpen = true;
    },
    editEquipment: function(contact) {
      this.editingContact = { ...contact };
      this.addEditContactPanelOpen = true;
    },

    async loadEquipment() {
      try {
        let result = await this.ContactService.getEquipment(
          this.contactId,
          this.pagingParams
        );
        this.equipment = result.data;
        this.totalEquipmentItems = result.count;

        console.log(
          "Loaded",
          this.equipment.length,
          "equipment items for page",
          this.pagingParams.page
        );
      } catch (error) {
        console.error(error);
        this.viewState = "Error";
      }
    },

    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      await this.loadEquipment();
    },

    async onPerPageChange(params) {
      this.updatePagingParams({
        limit: params.currentPerPage,
        page: params.currentPage,
      });
      await this.loadEquipment();
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
      });
      await this.loadEquipment();
    },

    updatePagingParams(newProps) {
      //console.log("updateParams", newProps);
      this.pagingParams = Object.assign({}, this.pagingParams, newProps);
      console.log("New params", this.pagingParams);
    },

    onColumnFilter(params) {
      console.log("onColumnFilter", params);
    },
    openEquipmentDetails: function(selectedRow) {
      // let ticketId = selectedRow.row.ticket_id;
      console.log(selectedRow.row.equipment_id);
      this.$router.push({
        name: "equipment-detail",
        params: { equipmentId: selectedRow.row.equipment_id },
      });
    },
  },
};
</script>
